import {useRef, useState} from 'react';
import './ServerList.css';

const servers = [
    {
        ip: "45.235.98.98:27033",
        name: "#1 Ancestral-Games.com.ar [PUBLICO]",
        slots: 16
    },
    {
        ip: "45.235.98.98:27035",
        name: "#2 Ancestral-Games.com.ar [AUTOMIX]",
        slots: 12
    },
    {
        ip: "45.235.98.98:27036",
        name: "#3 Ancestral-Games.com.ar [TTT]",
        slots: 18
    },
    {
        ip: "45.235.98.98:27037",
        name: "#4 Ancestral-Games.com.ar [GUNGAME]",
        slots: 12
    },
    {
        ip: "45.235.98.98:27038",
        name: "#5 Ancestral-Games.com.ar [BCM]",
        slots: 13
    },
    {
        ip: "45.235.98.98:27039",
        name: "#6 Ancestral-Games.com.ar [ZP]",
        slots: 16
    },
    {
        ip: "45.235.98.98:27040",
        name: "#7 Ancestral-Games.com.ar [MULTIMOD]",
        slots: 13
    }
]

const ServerList = (props) => {
    const [state, setState] = useState({
        selectedItem: null,
        pressedButton: null
    });
    
    var bodyOptionButton = useRef();

    var onClickItem = id => {
        return e => {
            setState({...state, selectedItem: id})
        }
    }

    var onMouseDownBodyOption = id => {
        return () => {
            if (state.selectedItem == null) return;
            
            setState({...state, pressedButton: id})
        }
    }

    var onMouseUpBodyOption = () => {
        setState({...state, pressedButton: null})
    }

    var handleKeyDown = e => {
        if (e.key === 'Enter') {
            onClickBodyOption()
        } else if (e.key === 'ArrowUp' && state.selectedItem !== null) {
            let item = state.selectedItem > 0 ? state.selectedItem-1 : 0
            setState({...state, selectedItem: item})
        } else if (e.key === 'ArrowDown' && state.selectedItem !== null) {
            let item = state.selectedItem < servers.length-1 ? state.selectedItem+1 : servers.length-1
            setState({...state, selectedItem: item})
        }
    }

    var onClickBodyOption = () => {
        if (!props.loadingScreen || state.selectedItem == null || servers[state.selectedItem].ip?.length < 1) return;
        
        props.loadingScreen.current.open("steam://connect/"+servers[state.selectedItem].ip)
    }

    var onClickCopyIP = () => {
        if (state.selectedItem == null) return;

        navigator.clipboard.writeText(servers[state.selectedItem].ip);
        bodyOptionButton.current.style.display = "block";
        setTimeout(() => {
            bodyOptionButton.current.style.display = "none";
        }, 1500);
    }

    return (
        <div id='serverlist-body' onKeyDown={handleKeyDown} tabIndex={0}>
            <div id="serverlist-separator">
                <div id="serverlist-separator-div1">Servers ({servers.length})</div>
                <div id="serverlist-separator-div2">Players</div>
                <div id="serverlist-separator-div3">Map</div>
            </div>

            <div id="serverlist-content">
                {servers.map((v, i) => <div key={"serverListItem"+i} className={state.selectedItem === i ? "serverlist-content-item-selected" : "serverlist-content-item"} onClick={onClickItem(i)} onDoubleClick={onClickBodyOption}>{v.name}</div>)}
            </div>

            <div id="serverlist-options">
                <div ref={bodyOptionButton} className="serverlist-option-copied">&#10004; Copied!</div>

                <div className={state.selectedItem === null ? "serverlist-option" : state.pressedButton === 0 ? "serverlist-option-allowed-click" : "serverlist-option-allowed"} 
                onMouseDown={onMouseDownBodyOption(0)} onMouseUp={onMouseUpBodyOption}
                onClick={onClickCopyIP}>Copy IP</div>

                <div className={state.selectedItem === null ? "serverlist-option" : state.pressedButton === 1 ? "serverlist-option-allowed-click" : "serverlist-option-allowed"} 
                onMouseDown={onMouseDownBodyOption(1)} onMouseUp={onMouseUpBodyOption}
                onClick={onClickBodyOption}>Connect</div>
            </div>
        </div>
    )
}

export default ServerList;