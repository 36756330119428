import {useImperativeHandle, forwardRef, useRef, useState} from 'react';
import './LoadingScreen.css';

const LoadingScreen = forwardRef((props, ref) => {
    const [load, setLoad] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("Connecting to the server");
    const loadingScreen = useRef();
    const loadContainer = useRef();

    var open = (serverConnectCmd) => {
        setCurrentMessage("Connecting to the server")
        loadingScreen.current.style.display = "flex";
        var count = 1
        for (var i = 0; i < 10; i++) {
            setTimeout(() => {
                let el = document.createElement('div')
                setLoad([...load, el])
                loadContainer.current.appendChild(el)
            }, 125*count)
            count++
        }
        
        setTimeout(() => {
            window.open(serverConnectCmd, "_blank");
            loadingScreen.current.style.display = "none";
            while (loadContainer.current.firstChild) {
                loadContainer.current.removeChild(loadContainer.current.firstChild);
            }
            setLoad([])
        }, 1500)
    }

    var clear = () => {
        loadingScreen.current.style.display = "none";
    }

    var falseLoading = (msg) => {
        setCurrentMessage(msg)
        loadingScreen.current.style.display = "flex";
        var count = 1
        for (var i = 0; i < 10; i++) {
            setTimeout(() => {
                let el = document.createElement('div')
                setLoad([...load, el])
                loadContainer.current.appendChild(el)
            }, 125*count)
            count++
        }
        
        setTimeout(() => {
            loadingScreen.current.style.display = "none";
            while (loadContainer.current.firstChild) {
                loadContainer.current.removeChild(loadContainer.current.firstChild);
            }
            setLoad([])
        }, 1500)
    }

    useImperativeHandle(ref, () => ({
        open,
        clear,
        falseLoading
    }));

    return (
        <div id="open-server-screen" ref={loadingScreen}>
            <div id="open-server-screen-loading">
                <div id="open-server-screen-loading-div1">Loading</div>
                <div id="open-server-screen-loading-div-spec">{currentMessage}</div>
                <div id="open-server-screen-loading-div2">
                    <div id="open-server-screen-loading-div2-load" ref={loadContainer}>
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default LoadingScreen;