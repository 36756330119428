import "./Landing.css";
import FloatingWindow from "../../components/FloatingWindow/FloatingWindow";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useRef } from "react";
import { useSelector } from 'react-redux';

function Landing(props) {
    const user = useSelector(state => state.user);
    const floatingWindow = useRef();
    const loadingScreen = useRef();
    const onClickOpenWindow = id => {
        return e => {
            if (!floatingWindow.current) return;
            
            floatingWindow.current.onClickOpenWindow(id);
        }
    }
    const onMouseMove = e => {
        if (!floatingWindow.current) return;

        floatingWindow.current.onMouseMove(e);
    }
    const onMouseUp = e => {
        if (!floatingWindow.current) return;

        floatingWindow.current.onMouseUp(e);
    }

    return (
        <div id="landing" onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
            <div id="option-container">
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(0)}>{!user.loged ? "INGRESAR" : "PERFIL"}</h1></div>
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(1)}>NUESTROS SERVIDORES</h1></div>
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(3)}>EVENTOS</h1></div>
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(2)}>SOBRE NOSOTROS</h1></div>
                <div className="ag">
                    <h1 className="ag-name">ANCESTRAL</h1>
                    <h1 className="ag-symbol">-</h1>
                    <h1 className="ag-name">GAMES</h1>
                </div>
            </div>

            <FloatingWindow ref={floatingWindow} loadingScreen={loadingScreen} config={props.config} />
            
            <LoadingScreen ref={loadingScreen}/>
        </div>
    )
}

export default Landing;