import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loged: false
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, payload) => {
            state.loged = true;
            state.user = payload.payload;
        },
        logout: (state) => {
            state = initialState;
        }
    }
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;