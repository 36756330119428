import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './Profile.css';

const Profile = (props) => {
    const verifyURL = props.config.baseURL+"/users/email_verification";

    const user = useSelector(state => state.user);
    const [state, setState] = useState({code: "", invalid: false})

    var handleEnterVerify = e => {
        if (e.key !== 'Enter') return;

        handleVerify(e)
    }

    var handleVerify = e => {
        if (!props.loadingScreen || state.invalid) return;
        
        props.loadingScreen.current.falseLoading("Validating code")

        axios.post(verifyURL, {code: state.code}, {
            headers: {
              Authorization: "Bearer "+user.user.token
            }
          }).then(r => {
            window.location.reload();
        }).catch(e => {
            setState({...state, invalid: true})
            props.loadingScreen.current.clear()
        })
    }

    return (
        <div id='profile-body'>
            <div id="profile-separator">
                <div id="profile-separator-div1">Perfil</div>
            </div>

            <div id="profile-content">
                {user.user.email_verified !== true ? 
                <div className="profile-content-box" onKeyDown={handleEnterVerify}>
                    <h1>Verifica tu email</h1>
                    <input className="profile-input" maxLength={6} value={state.code} onChange={e => setState({...state, code: e.target.value, invalid: false})} placeholder='Code' type="text" />
                </div> : 
                <div className="profile-content-box">
                    <h1>Verificado!</h1>
                </div>}
            </div>

            <div id="profile-options">
                <div className={state.code.length === 6 && !state.invalid ? "profile-option-allowed" : "profile-option"} onClick={handleVerify}>Verificar</div>
            </div>
        </div>
    )
}

export default Profile;