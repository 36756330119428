import './About.css';
import iconFacebook from './facebook_icon.png';
import iconInstagram from './instagram_icon.png';
import iconYoutube from './youtube_icon.png';
import iconEmail from './email_icon.png';
import iconX from './x_icon.png';
import iconDiscord from './discord_icon.png';

const historyParagraph = "Ancestral nace en 2011 con un servidor de hide'n'seek con bloques (bcm - bunny-hop) creado por neo. Se utilizaba el clásico blockmaker público del momento. En un intento por agregar funcionalidades extra al blockmaker, neo conoció a Gonza, y le solicitó algunos cambios al plugin; bloques con movimiento, propiedades generales para todos los bloques, entre otros cambios. Gonza aceptó y poco tiempo después envió a neo el plugin modificado con los cambios solicitados, ahí es cuando todo comienzó. Horas y horas de muchas personas han sido dedicadas a bunnear los clásicos bunnies de Ancestral. La gente quería mas, así que abrieron un segundo servidor hide'n'seek bcm, pero no-slow para los mas newbies. Los servidores permanecian llenos practicamente todo el tiempo. Fue tal el exito de los servidores, que Gonza decidió recrear desde cero el blockmaker, y ademas agregarle un sistema de mejoras para los jugadores, por lo que agregó un sistema de creación y autenticación de usuarios, donde los usuarios subian de nivel en base a cumplir con ciertos objetivos del juego, como terminar bunnies o completar logros. Gonza ha estado desarrollando el clásico modo caracteristico de Ancestral-Games durante varios años, y actualmente lo sigue haciendo. Su objetivo es traerles nuevamente a todos los jugadores, tanto los de siempre como los nuevos por venir, la mejor de las experiencias de juego, pero esta vez si a gran escala y de manera profesional."
const presentParagraph = "Despues de mucho tiempo de estar sin servidores oficiales de Ancestral, decidí volver al ruedo. Yo, Gonza, me encuentro creando una nueva experiencia para todos ustedes, en la cual podrán ser parte de la gran comunidad que somos y que supimos ser, pero esta vez, ¡Tendremos nuestro propio espacio!"

const About = () => {
    return (
        <div id='about-body'>
            <div id="about-separator" />

            <div id="about-content">
                <h1>Sobre nosotros</h1>
                <h2>Nuestras redes</h2>
                <div id="about-contact">
                    <a href="https://discord.gg/wwPh886f8r" target="_blank" rel="noreferrer"><img className='about-img-social-icon' src={iconDiscord} alt="Discord" /></a>
                    <a href="mailto:AncestralGames.CS@gmail.com"><img className='about-img-social-icon' src={iconEmail} alt="Email" /></a>
                    <a href="https://www.youtube.com/@AncestralGamesCS" target="_blank" rel="noreferrer"><img className='about-img-social-icon' src={iconYoutube} alt="Youtube" /></a>
                    <a href="https://www.facebook.com/AncestralGames.CS" target="_blank" rel="noreferrer"><img className='about-img-social-icon' src={iconFacebook} alt="Facebook" /></a>
                    <a href="https://www.instagram.com/AncestralGames.CS" target="_blank" rel="noreferrer"><img className='about-img-social-icon' src={iconInstagram} alt="Instagram" /></a>
                    <a href="https://www.x.com/AncestralGames0" target="_blank" rel="noreferrer"><img className='about-img-social-icon' src={iconX} alt="X" /></a>
                </div>
                <h2>Nuestra historia</h2>
                <p>{historyParagraph}</p>
                <h2>Actualidad</h2>
                <p>{presentParagraph}</p>
            </div>

            <div id="about-options">
                <p>Desarrollado con ❤️ por <a href="https://www.facebook.com/Gonza.Scripter" target="_blank" rel="noreferrer">Gonza.-*</a></p>
            </div>
        </div>
    )
}

export default About;