import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useEffect } from "react";
import Landing from "./pages/Landing/Landing";
//import Home from "./pages/Home/Home";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { login } from './redux/userSlice';
/* import dotenv from 'dotenv';
dotenv.config() */

const config = {
    baseURL: "https://ancestral-games.com.ar/api" // http://localhost:3001 || process.env.API_URL
}

const checkStatus = config.baseURL+"/users/me";

function App() {
  const dispatch = useDispatch();
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // onMount: if has token, get /me status and update state if applicable
  useEffect(() => {
    const token = getCookie('agUsertoken'); // Nombre de la cookie donde se guarda el token

    if (typeof token !== "string" || token.length < 4) {
      return;
    }

    axios.get(checkStatus, {
      headers: {
        Authorization: "Bearer "+token
      }
    }).then(r => {
      dispatch(login({
        id: r.data.id,
        username: r.data.username,
        email: r.data.email,
        email_verified: r.data.email_verified,
        role: r.data.role,
        token: token
      }))
    }).catch(e => null)
  }, [dispatch])
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing config={config}/>} />
        {/* <Route path="/home" element={<Home />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
