import {useImperativeHandle, forwardRef, useState, useRef} from 'react';
import ServerList from '../ServerList/ServerList';
import About from '../About/About';
import Login from '../Login/Login';
import Profile from '../Profile/Profile';
import './FloatingWindow.css';
import { useSelector } from 'react-redux';

const FloatingWindow = forwardRef((props, ref) => {
    const user = useSelector(state => state.user);
    const dinamicView = [
        {
            name: "Internet",
            component: !user.loged ? <Login loadingScreen={props.loadingScreen} config={props.config}/> : <Profile loadingScreen={props.loadingScreen} config={props.config} />
        },
        {
            name: "Favorites",
            component: <ServerList loadingScreen={props.loadingScreen}/>
        },
        {
            name: "History",
            component: <About />
        },
        {
            name: "Spectate",
            component: <ServerList loadingScreen={props.loadingScreen}/>
        },
        {
            name: "Lan",
            component: <ServerList loadingScreen={props.loadingScreen}/>
        },
        {
            name: "Friends",
            component: <ServerList loadingScreen={props.loadingScreen}/>
        },
    ]

    const [state, setState] = useState({
        offset: [0,0],
        down: false,
        selectedOption: null,
        selectedItem: null
    });
    var floatingWindow = useRef();
    var navbarOption = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]

    var onMouseDown = e => {
        setState({
            ...state,
            down: true,
            offset: [
                floatingWindow.current.offsetLeft - e.clientX,
                floatingWindow.current.offsetTop - e.clientY
            ]
        });
    }

    var onMouseUp = e => {
        setState({...state, down: false})
    }

    var onMouseMove = e => {
        e.preventDefault();
        if (state.down) {
            floatingWindow.current.style.left = (e.clientX + state.offset[0]) + 'px';
            floatingWindow.current.style.top  = (e.clientY + state.offset[1]) + 'px';
        }
    }

    var onClickWindowQuit = e => {
        closeWindow()
    }

    var closeWindow = () => {
        floatingWindow.current.style.display = "none";
    }

    var onClickOpenWindow = (id) => {
        floatingWindow.current.style.display = "flex";
        setState({...state, selectedOption: id})
    }

    var dinamicSelectOptionClass = id => {
        return id === state.selectedOption ? "floating-window-navbar-option-selected" : "floating-window-navbar-option"
    }

    var onClickOption = id => {
        return e => {
            setState({...state, selectedOption: id})
        }
    }

    useImperativeHandle(ref, () => ({
        onMouseMove,
        onMouseUp,
        onClickOpenWindow,
    }));

    return (
        <div ref={floatingWindow} id="floating-window">
            <div id="floating-window-top" onMouseDown={onMouseDown}>
                <div>Servers</div>
                <button id="quitWindowButton" onClick={onClickWindowQuit}>&#10006;</button>
            </div>

            <div id="floating-window-navbar">
                {dinamicView.map((t, i) => <div key={"floatingWindowNavbarOption"+i} ref={navbarOption[i]} className={dinamicSelectOptionClass(i)} onClick={onClickOption(i)}>{t.name}</div>)}
            </div>

            <div className="floating-window-body-separator" />

            {state.selectedOption == null ? null : dinamicView[state.selectedOption].component}
        </div>
    )
})

export default FloatingWindow;