import { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/userSlice';
import './Login.css';

const optionLogin = 0;
const optionRegister = 1;
const optionForgotPassword = 2;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = (props) => {
    const loginURL = props.config.baseURL+"/users/login";
    const registerURL = props.config.baseURL+"/users/register";
    const forgotURL = props.config.baseURL+"/users/forgot_password";

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        new_username: '',
        new_email: '',
        new_password: '',
        new_password2: '',
        get_pw_email: ''
    });
    const [state, setState] = useState({
        selectedOption: optionLogin
    })

    var validLogin = !user.loged && formData.username.length > 1 && formData.password.length > 3
    var validRegister = !user.loged && formData.new_username.length > 1 && formData.new_password.length > 3 && formData.new_password === formData.new_password2 && emailRegex.test(formData.new_email)
    var validForget = !user.loged && emailRegex.test(formData.get_pw_email)

    var handleChangeOption = type => {
        return e => {
            setState({...state, selectedOption: type});
        }
    };

    var getOptionClass = o => state.selectedOption === o ? "login-separator-div-selected" : "login-separator-div"

    var handleChangeInput = input => {
        return e => {
            setFormData({...formData, [input]: e.target.value});
        }
    }

    var handleEnterLogin = e => {
        if (e.key !== 'Enter') return;

        handleLogin(e)
    }

    var handleLogin = e => {
        if (!props.loadingScreen || !validLogin) return;
        
        props.loadingScreen.current.falseLoading("Validating credentials")

        axios.post(loginURL, {
            username: formData.username,
            password: formData.password
        }).then(r => {
            document.cookie = `agUsertoken=${r.data.token}; path=/; secure; samesite=strict;`;
            dispatch(login({
                id: r.data.id,
                username: r.data.username,
                email: r.data.email,
                email_verified: r.data.email_verified,
                role: r.data.role,
                token: r.data.token
            }))
        }).catch(e => {
            props.loadingScreen.current.clear()
        })
    }

    var handleEnterRegister = e => {
        if (e.key !== 'Enter') return;

        handleRegister(e)
    }

    var handleRegister = e => {
        if (!props.loadingScreen || !validRegister) return;
        
        props.loadingScreen.current.falseLoading("Creating your account")

        axios.post(registerURL, {
            username: formData.new_username,
            email: formData.new_email,
            password: formData.new_password
        }).then(r => {
            document.cookie = `agUsertoken=${r.data.token}; path=/; secure; samesite=strict;`;
            dispatch(login({
                id: r.data.id,
                username: r.data.username,
                email: r.data.email,
                email_verified: false,
                role: r.data.role,
                token: r.data.token
            }))
        }).catch(e => {
            props.loadingScreen.current.clear()
        })
    }

    var handleEnterForget = e => {
        if (e.key !== 'Enter') return;

        handleForget(e)
    }

    var handleForget = e => {
        if (!props.loadingScreen || !validForget) return;
        
        props.loadingScreen.current.falseLoading("Sending your password to your email")

        axios.post(forgotURL, {
            email: formData.get_pw_email,
        }).then(r => null).catch(e => null)
    }

    return (
        <div id='login-body'>
            <div id="login-separator">
                <div className={getOptionClass(optionLogin)} onClick={handleChangeOption(optionLogin)}>Login</div>
                <div className={getOptionClass(optionRegister)} onClick={handleChangeOption(optionRegister)}>Register</div>
            </div>

            <div id="login-content">
                { state.selectedOption === optionLogin ?
                <div className="login-content-box" onKeyDown={handleEnterLogin}>
                    <h1>Usuario o Email</h1>
                    <input className="login-input" value={formData.username} onChange={handleChangeInput("username")} placeholder='Username/Email' type="text" />

                    <h1>Contraseña</h1>
                    <input className="login-input" value={formData.password} onChange={handleChangeInput("password")} placeholder='Password' type="password" />
                </div> : state.selectedOption === optionRegister ?
                <div className="login-content-box" onKeyDown={handleEnterRegister}>
                    <h1>Usuario</h1>
                    <input className="login-input" value={formData.new_username} onChange={handleChangeInput("new_username")} placeholder='Username' type="text" />
                    
                    <h1>Email</h1>
                    <input className="login-input" value={formData.new_email} onChange={handleChangeInput("new_email")} placeholder='Email' type="email" />

                    <h1>Contraseña</h1>
                    <input className="login-input" value={formData.new_password} onChange={handleChangeInput("new_password")} placeholder='Password' type="password" />

                    <h1>Repite la contraseña</h1>
                    <input className="login-input" value={formData.new_password2} onChange={handleChangeInput("new_password2")} placeholder='Password' type="password" />
                </div> : 
                <div className="login-content-box" onKeyDown={handleEnterForget}>
                    <h1>Ingresa tu Email</h1>
                    <input className="login-input" value={formData.get_pw_email} onChange={handleChangeInput("get_pw_email")} placeholder='Email' type="email" />
                </div>}
            </div>

            <div id="login-options">
                {state.selectedOption === optionLogin ? <div className="login-option-allowed" onClick={handleChangeOption(optionForgotPassword)}>¿Forgot password?</div> : null}
                {state.selectedOption === optionLogin ? <div className={validLogin ? "login-option-allowed" : "login-option"} onClick={handleLogin}>Login</div> : state.selectedOption === optionRegister ? <div className={validRegister ? "login-option-allowed" : "login-option"} onClick={handleRegister}>Register</div> : <div className={validForget ? "login-option-allowed" : "login-option"} onClick={handleForget}>Get password</div>}
            </div>
        </div>
    )
}

export default Login;